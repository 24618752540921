@charset 'UTF-8';

@use '../foundation/mixin' as r;
@use '../abstruct/variable' as v;

.l-header {
  position: relative;
  z-index: 20;

  @include r.mq(mdless) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;
    width: 100%;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    padding-top: 27%;
    content: '';
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;

    @include r.mq(mdless) {
      background-image: inherit;
      // padding-top: calc(205 / 750 * 100%)
    }
  }
}

.c-header-about {
  &:before {
    background-image: url(v.$filePath + 'assets/images/about/img_bg_header_about.png');
  }
}

.c-header-recruit {
  &:before {
    background-image: url(v.$filePath + 'assets/images/recruit/img_bg_header_recruit.png');
  }
}

.c-header-contact {
  &:before {
    background-image: url(v.$filePath + 'assets/images/contact/img_bg_header_contact.png');
  }
}

.c-header-news {
  &:before {
    background-image: url(v.$filePath + 'assets/images/news/img_bg_header_news.png');
  }
}

.c-header-supporters {
  &:before {
    background-image: url(v.$filePath + 'assets/images/supporters/img_bg_header_supporters.png');
  }
}

.c-header-works {
  &:before {
    background-image: url(v.$filePath + 'assets/images/works/img_bg_header_works.png');
  }
}

.c-header-service {
  &:before {
    background-image: url(v.$filePath + 'assets/images/service/img_bg_header_service.png');
  }
}

.c-header-vision {
  &:before {
    background-image: url(v.$filePath + 'assets/images/vision/img_bg_header_vision.png');
  }
}

.c-header_inner {
  @apply flex items-center justify-between;
  width: calc(419 / 677 * 100%);
  padding-top: 2%;
  margin: 0 auto;

  @include r.mq(mdless) {
    width: 100%;
    padding: 3% 3%;
    background-color: #ffffff;
  }
}

.c-logo_sdgslab {
  display: block;
  width: calc(150 / 677 * 100%);

  @include r.mq(mdless) {
    width: calc(290 / 690 * 100%);
  }

  a {
    display: block;
  }
}

.c-menu_button {
  width: calc(40 / 677 * 100%);
  height: calc(40 / 677 * 100%);

  @include r.mq(mdless) {
    width: calc(97 / 750 * 100%);
    height: calc(97 / 750 * 100%);
    line-height: 1;
  }

  img {
    width: 100%;
  }
}

.c-header_menu-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 0;
  transition: background-color 0.3s ease-out;

  &.open {
    background-color: v.$color_menu;
    opacity: 0.95;
  }
}

.c-header_menu {
  width: calc(515 / 677 * 100%);
  padding-bottom: 5%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 100vh 100vh;

  @include r.mq(mdless) {
    width: calc(650 / 750 * 100%);
    padding-bottom: 17%;
  }

  &.open {
    opacity: 0.95;
  }
}

.c-header_menu-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 20;
  display: none;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  transform: translate(-50%, -50%);

  @include r.mq(mdless) {
    top: 15%;
    height: 770px;
    overflow-y: scroll;
    transform: translate(-50%, 0);
    // overflow-y: scroll;
  }

  &.open {
    opacity: 0.95;
  }
}

.c-header_menu-inner {
  position: relative;
  width: calc(341 / 515 * 100%);
  margin: 0 auto;

  @include r.mq(mdless) {
    width: calc(483 / 650 * 100%);
  }

  .c-menu_button {
    position: absolute;
    top: calc(-10 / 341 * 100%);
    right: calc(-20 / 341 * 100%);
    width: calc(30 / 341 * 100%);
    height: calc(30 / 341 * 100%);

    @include r.mq(mdless) {
      width: calc(97 / 483 * 100%);
      height: calc(97 / 483 * 100%);
    }

    img {
      width: 100%;
    }
  }

  .c-nav {
    margin-top: 8%;

    @include r.mq(mdless) {
      margin-top: 20%;
    }

    a {
      &:nth-of-type(1n + 2) {
        @include r.mq(mdless) {
          margin-top: 4%;
        }
      }
    }
  }

  .c-sns {
    @include r.mq(mdless) {
      justify-content: start;
      width: 100%;
    }
  }

  .c-sns01 {
    @include r.mq(mdless) {
      width: calc(80 / 483 * 100%);
      margin-right: calc(65 / 483 * 100%);
    }
  }

  .c-sns02 {
    @include r.mq(mdless) {
      width: calc(80 / 483 * 100%);
      margin-right: calc(65 / 483 * 100%);
    }
  }

  .c-fukidashi {
    @include r.mq(mdless) {
      position: absolute;
      top: 50%;
      right: -17%;
      width: calc(267 / 483 * 100%);
      transform: translateY(-50%);
    }
  }

  .c-under {
    @apply justify-end;
    position: relative;
    top: 1rem;

    @include r.mq(mdless) {
      @apply justify-start items-start;
      top: 0;
      margin-top: 17%;
    }
  }
}

.img_sdgslab {
  width: calc(253 / 341 * 100%);
  padding-top: 10%;
  margin: 0 auto;

  @include r.mq(mdless) {
    width: calc(388 / 483 * 100%);
    padding-top: 30%;
  }
}

[data-close-botton] {
  font-size: 0;

  @include r.mq(mdless) {
    display: none;
  }
}

[data-close-botton-sp] {
  display: none;
  font-size: 0;
}

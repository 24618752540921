.l-header {
  position: relative;
  z-index: 20;
}
@media screen and (max-width: 767px) {
  .l-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;
    width: 100%;
  }
}
.l-header:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  padding-top: 27%;
  content: "";
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  .l-header:before {
    background-image: inherit;
  }
}

.c-header-about:before {
  background-image: url("/assets/images/about/img_bg_header_about.png");
}

.c-header-recruit:before {
  background-image: url("/assets/images/recruit/img_bg_header_recruit.png");
}

.c-header-contact:before {
  background-image: url("/assets/images/contact/img_bg_header_contact.png");
}

.c-header-news:before {
  background-image: url("/assets/images/news/img_bg_header_news.png");
}

.c-header-supporters:before {
  background-image: url("/assets/images/supporters/img_bg_header_supporters.png");
}

.c-header-works:before {
  background-image: url("/assets/images/works/img_bg_header_works.png");
}

.c-header-service:before {
  background-image: url("/assets/images/service/img_bg_header_service.png");
}

.c-header-vision:before {
  background-image: url("/assets/images/vision/img_bg_header_vision.png");
}

.c-header_inner {
  @apply flex items-center justify-between;
  width: calc(419 / 677 * 100%);
  padding-top: 2%;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .c-header_inner {
    width: 100%;
    padding: 3% 3%;
    background-color: #ffffff;
  }
}

.c-logo_sdgslab {
  display: block;
  width: calc(150 / 677 * 100%);
}
@media screen and (max-width: 767px) {
  .c-logo_sdgslab {
    width: calc(290 / 690 * 100%);
  }
}
.c-logo_sdgslab a {
  display: block;
}

.c-menu_button {
  width: calc(40 / 677 * 100%);
  height: calc(40 / 677 * 100%);
}
@media screen and (max-width: 767px) {
  .c-menu_button {
    width: calc(97 / 750 * 100%);
    height: calc(97 / 750 * 100%);
    line-height: 1;
  }
}
.c-menu_button img {
  width: 100%;
}

.c-header_menu-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 0;
  transition: background-color 0.3s ease-out;
}
.c-header_menu-bg.open {
  background-color: #fcc30b;
  opacity: 0.95;
}

.c-header_menu {
  width: calc(515 / 677 * 100%);
  padding-bottom: 5%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 100vh 100vh;
}
@media screen and (max-width: 767px) {
  .c-header_menu {
    width: calc(650 / 750 * 100%);
    padding-bottom: 17%;
  }
}
.c-header_menu.open {
  opacity: 0.95;
}

.c-header_menu-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 20;
  display: none;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 767px) {
  .c-header_menu-wrap {
    top: 15%;
    height: 770px;
    overflow-y: scroll;
    transform: translate(-50%, 0);
  }
}
.c-header_menu-wrap.open {
  opacity: 0.95;
}

.c-header_menu-inner {
  position: relative;
  width: calc(341 / 515 * 100%);
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .c-header_menu-inner {
    width: calc(483 / 650 * 100%);
  }
}
.c-header_menu-inner .c-menu_button {
  position: absolute;
  top: calc(-10 / 341 * 100%);
  right: calc(-20 / 341 * 100%);
  width: calc(30 / 341 * 100%);
  height: calc(30 / 341 * 100%);
}
@media screen and (max-width: 767px) {
  .c-header_menu-inner .c-menu_button {
    width: calc(97 / 483 * 100%);
    height: calc(97 / 483 * 100%);
  }
}
.c-header_menu-inner .c-menu_button img {
  width: 100%;
}
.c-header_menu-inner .c-nav {
  margin-top: 8%;
}
@media screen and (max-width: 767px) {
  .c-header_menu-inner .c-nav {
    margin-top: 20%;
  }
}
@media screen and (max-width: 767px) {
  .c-header_menu-inner .c-nav a:nth-of-type(1n + 2) {
    margin-top: 4%;
  }
}
@media screen and (max-width: 767px) {
  .c-header_menu-inner .c-sns {
    justify-content: start;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .c-header_menu-inner .c-sns01 {
    width: calc(80 / 483 * 100%);
    margin-right: calc(65 / 483 * 100%);
  }
}
@media screen and (max-width: 767px) {
  .c-header_menu-inner .c-sns02 {
    width: calc(80 / 483 * 100%);
    margin-right: calc(65 / 483 * 100%);
  }
}
@media screen and (max-width: 767px) {
  .c-header_menu-inner .c-fukidashi {
    position: absolute;
    top: 50%;
    right: -17%;
    width: calc(267 / 483 * 100%);
    transform: translateY(-50%);
  }
}
.c-header_menu-inner .c-under {
  @apply justify-end;
  position: relative;
  top: 1rem;
}
@media screen and (max-width: 767px) {
  .c-header_menu-inner .c-under {
    @apply justify-start items-start;
    top: 0;
    margin-top: 17%;
  }
}

.img_sdgslab {
  width: calc(253 / 341 * 100%);
  padding-top: 10%;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .img_sdgslab {
    width: calc(388 / 483 * 100%);
    padding-top: 30%;
  }
}

[data-close-botton] {
  font-size: 0;
}
@media screen and (max-width: 767px) {
  [data-close-botton] {
    display: none;
  }
}

[data-close-botton-sp] {
  display: none;
  font-size: 0;
}

.l-footer {
  position: relative;
  padding-top: 3rem;
  overflow: hidden;
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .l-footer {
    padding-top: 7%;
  }
}
.l-footer:before {
  display: block;
  width: 100%;
  height: 0;
  padding-top: calc(50 / 3841 * 100%);
  content: "";
  background-repeat: repeat-x;
  background-size: cover;
}

.c-nav {
  @apply flex flex-wrap;
  width: calc(380 / 380 * 100%);
  margin: 0 auto 0;
}
@media screen and (max-width: 767px) {
  .c-nav {
    @apply block;
  }
}
.c-nav a {
  @apply block;
  width: calc(117 / 380 * 100%);
  margin-right: 3.8%;
}
@media screen and (max-width: 767px) {
  .c-nav a {
    width: 100%;
    margin-right: 0;
  }
}
.c-nav a:nth-of-type(3n + 3) {
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  .c-nav a:nth-of-type(1n + 2) {
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) {
  .c-nav a:nth-of-type(1n + 4) {
    margin-top: 3.8%;
  }
}

.c-sns01 {
  width: 3.7rem;
  margin-right: 2.5rem;
}
@media screen and (max-width: 767px) {
  .c-sns01 {
    width: calc(82 / 750 * 100%);
    margin-right: calc(65 / 750 * 100%);
  }
}
.c-sns01 img {
  width: 100%;
}

.c-sns02 {
  width: 4rem;
  margin-right: 2.5rem;
}
@media screen and (max-width: 767px) {
  .c-sns02 {
    width: calc(82 / 750 * 100%);
    margin-right: calc(65 / 750 * 100%);
  }
}
.c-sns02 img {
  width: 100%;
}

.c-fukidashi {
  width: 13rem;
}
@media screen and (max-width: 767px) {
  .c-fukidashi {
    width: calc(262 / 750 * 100%);
  }
}
.c-fukidashi img {
  width: 100%;
}

.c-footer_inner {
  width: calc(380 / 677 * 100%);
  padding-top: 5rem;
  margin: 0 auto 3rem;
}
@media screen and (max-width: 767px) {
  .c-footer_inner {
    width: 100%;
    padding-top: 0;
    margin: 0 auto 3vw;
  }
}
@media screen and (max-width: 767px) {
  .c-footer_inner .c-nav a {
    padding: 3% 0;
    font-size: 4vw;
    border-bottom: 2px solid #fcc30b;
    @apply text-center font-bold;
  }
}
@media screen and (max-width: 767px) {
  .c-footer_inner .c-nav a img {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .c-footer_inner .c-nav a span {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .c-footer_inner .c-nav a:nth-of-type(1n + 2) {
    margin-top: 0;
  }
}

.c-footer-about:before {
  background-color: #00689d;
}

.c-footer-recruit:before {
  background-color: #dd1367;
}

.c-footer-contact:before {
  background-color: #bf8b2e;
}

.c-footer-news:before {
  background-color: #fd9d24;
}

.c-footer-supporters:before {
  background-color: #26bde2;
}

.c-footer-works:before {
  background-color: #ff3a21;
}

.c-footer-service:before {
  background-color: #56c02b;
}

.c-footer-vision:before {
  background-color: #fcc30b;
}

.c-footer-top {
  position: relative;
}
.c-footer-top:before {
  padding-top: calc(158 / 3841 * 100%);
  background-image: url("/assets/images/top/img_pages_underline.png");
}
@media screen and (max-width: 767px) {
  .c-footer-top:before {
    padding-top: calc(18 / 750 * 100%);
    background-image: url("/assets/images/top/img_pages_underline_sp.png");
  }
}

.c-under {
  margin-top: 1rem;
}
@media screen and (min-width: 768px) {
  .c-under {
    @apply flex items-end justify-between;
  }
}
@media screen and (max-width: 767px) {
  .c-under {
    @apply flex items-center flex-col-reverse;
  }
}
.c-under .c-logo_sdgslab {
  width: calc(140 / 380 * 100%);
}
@media screen and (max-width: 767px) {
  .c-under .c-logo_sdgslab {
    width: calc(403 / 750 * 100%);
  }
}

.c-sns {
  @apply flex items-center;
}
@media screen and (max-width: 767px) {
  .c-sns {
    @apply justify-center;
  }
}

.c-cpoy {
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: 300;
}
@media screen and (max-width: 767px) {
  .c-cpoy {
    @apply text-center;
    font-size: 2.8vw;
  }
}

[data-whatinput=mouse] *:focus,
[data-whatinput=touch] *:focus {
  outline: none;
}

.c-pages_head {
  @apply text-center;
  width: calc(383 / 677 * 100%);
  margin: 8% auto 0;
}

@media screen and (max-width: 767px) {
  .c-pages_bg {
    width: 100%;
    margin: 0 auto 0;
  }
  .c-pages_bg:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: calc(207 / 750 * 100%);
    content: "";
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
  }
}

.c-pages_title {
  width: calc(117 / 383 * 100%);
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .c-pages_title {
    width: calc(483 / 750 * 100%);
    padding-top: 27%;
    margin: 18% auto 0;
  }
  .c-pages_title img {
    width: 100%;
  }
}

.c-body {
  width: calc(380 / 677 * 100%);
  margin: 2vw auto 0;
}
@media screen and (max-width: 767px) {
  .c-body {
    width: calc(577 / 750 * 100%);
    margin: 6vw auto 0;
  }
}

.c-key {
  margin: 4% auto 0;
}
.c-hero {
  margin-top: 10%;
}
@media screen and (max-width: 767px) {
  .c-hero {
    width: calc(567 / 750 * 100%);
  }
}

.c-bg {
  position: relative;
  padding-bottom: 5%;
}
@media screen and (max-width: 767px) {
  .c-bg {
    padding-bottom: 12%;
  }
}
.c-bg:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 0;
  margin-top: 6%;
  overflow: hidden;
  content: "";
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  .c-bg:before {
    margin-top: 27%;
  }
}

.c-pages_subtitle {
  margin-top: 2vw;
  font-size: 1.6vw;
  @apply font-bold;
}
@media screen and (max-width: 767px) {
  .c-pages_subtitle {
    margin-top: 4vw;
    font-size: 4.6666666667vw;
  }
}

.c-pages_text {
  margin-top: calc(10 / 383 * 100%);
  font-size: 1.25vw;
  line-height: 3vw;
}
@media screen and (max-width: 767px) {
  .c-pages_text {
    font-size: 4vw;
    line-height: 7vw;
    font-feature-settings: "palt";
  }
}

.c-pages-top-view {
  position: fixed;
  right: 16vw;
  bottom: 13rem;
  z-index: 19;
  display: block;
  width: 4rem;
  height: 4rem;
}
@media screen and (max-width: 767px) {
  .c-pages-top-view {
    right: 0;
    right: 3vw;
    bottom: 0;
    bottom: 3vw;
  }
}

.js-page-top {
  display: block;
}
.js-page-top:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: 5rem;
  content: "";
  background-image: url("/assets/images/common/img_scroll_top.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.color01 {
  color: #000;
}

.color02 {
  color: #fcc30b;
}

.color03 {
  color: #56c02b;
}

.color04 {
  color: #ff3a21;
}

.color05 {
  color: #00689d;
}

.color06 {
  color: #26bde2;
}

.color07 {
  color: #dd1367;
}

.color08 {
  color: #fd9d24;
}

.color09 {
  color: #bf8b2e;
}

[data-observe] {
  opacity: 0;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  transform: translateY(20%);
}
$ROOT: '/assets/images/';
$baseMargin: 8;
$filePath: '/';
// $filePath: '/sdgslab/';
$color_about: #00689d;
$color_font_about: #19486a;
$color_recruit: #dd1367;
$color_font_recruit: #dd1367;
$color_contact: #bf8b2e;
$color_font_contact: #bf8b2e;
$color_news: #fd9d24;
$color_font_news: #fd9d24;
$color_supporters: #26bde2;
$color_font_supporters: #26bde2;
$color_top: #fd9d24;
$color_font_top: #fd9d24;
$color_works: #ff3a21;
$color_font_works: #ff3a21;
$color_service: #56c02b;
$color_font_service: #56c02b;
$color_vision: #fcc30b;
$color_font_vision: #fcc30b;
$color_menu: #fcc30b;

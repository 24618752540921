@charset 'UTF-8';

@use '../foundation/mixin' as r;
@use '../abstruct/variable' as v;

.l-footer {
  position: relative;
  padding-top: 3rem;
  overflow: hidden;
  background-color: #fff;

  @include r.mq(mdless) {
    // min-width: 100%;
    padding-top: 7%;
  }

  &:before {
    display: block;
    width: 100%;
    height: 0;
    padding-top: calc(50 / 3841 * 100%);
    content: '';
    background-repeat: repeat-x;
    background-size: cover;
  }
}

.c-nav {
  @apply flex flex-wrap;
  width: calc(380 / 380 * 100%);
  margin: 0 auto 0;

  @include r.mq(mdless) {
    @apply block;
  }

  a {
    @apply block;
    width: calc(117 / 380 * 100%);
    margin-right: 3.8%;

    @include r.mq(mdless) {
      width: 100%;
      margin-right: 0;
    }

    &:nth-of-type(3n + 3) {
      margin-right: 0;
    }

    &:nth-of-type(1n + 2) {
      @include r.mq(mdless) {
        margin-top: 0;
      }
    }

    &:nth-of-type(1n + 4) {
      @include r.mq(mdover) {
        margin-top: 3.8%;
      }
    }
  }
}

.c-sns01 {
  width: 3.7rem;
  margin-right: 2.5rem;

  @include r.mq(mdless) {
    width: calc(82 / 750 * 100%);
    margin-right: calc(65 / 750 * 100%);
  }

  img {
    width: 100%;
  }
}

.c-sns02 {
  width: 4rem;
  margin-right: 2.5rem;

  @include r.mq(mdless) {
    width: calc(82 / 750 * 100%);
    margin-right: calc(65 / 750 * 100%);
  }

  img {
    width: 100%;
  }
}

.c-fukidashi {
  width: 13rem;

  @include r.mq(mdless) {
    width: calc(262 / 750 * 100%);
  }

  img {
    width: 100%;
  }
}

.c-footer_inner {
  width: calc(380 / 677 * 100%);
  padding-top: 5rem;
  margin: 0 auto 3rem;

  @include r.mq(mdless) {
    width: 100%;
    padding-top: 0;
    margin: 0 auto 3vw;
  }

  .c-nav {
    a {
      @include r.mq(mdless) {
        padding: 3% 0;
        font-size: 4vw;
        border-bottom: 2px solid #fcc30b;
        @apply text-center font-bold;
      }

      img {
        @include r.mq(mdless) {
          display: none;
        }
      }

      span {
        @include r.mq(mdover) {
          display: none;
        }
      }

      &:nth-of-type(1n + 2) {
        @include r.mq(mdless) {
          margin-top: 0;
        }
      }
    }
  }
}

.c-footer-about {
  &:before {
    background-color: v.$color_about;
  }
}

.c-footer-recruit {
  &:before {
    background-color: v.$color_recruit;
  }
}

.c-footer-contact {
  &:before {
    background-color: v.$color_contact;
  }
}

.c-footer-news {
  &:before {
    background-color: v.$color_news;
  }
}

.c-footer-supporters {
  &:before {
    background-color: v.$color_supporters;
  }
}

.c-footer-works {
  &:before {
    background-color: v.$color_works;
  }
}

.c-footer-service {
  &:before {
    background-color: v.$color_service;
  }
}

.c-footer-vision {
  &:before {
    background-color: v.$color_vision;
  }
}

.c-footer-top {
  position: relative;

  &:before {
    padding-top: calc(158 / 3841 * 100%);
    background-image: url(v.$filePath + 'assets/images/top/img_pages_underline.png');

    @include r.mq(mdless) {
      padding-top: calc(18 / 750 * 100%);
      background-image: url(v.$filePath + 'assets/images/top/img_pages_underline_sp.png');
    }
  }
}

.c-contents_foucs {
}

.c-under {
  margin-top: 1rem;

  @include r.mq(mdover) {
    @apply flex items-end justify-between;
  }

  @include r.mq(mdless) {
    @apply flex items-center flex-col-reverse;
  }

  .c-logo_sdgslab {
    width: calc(140 / 380 * 100%);

    @include r.mq(mdless) {
      width: calc(403 / 750 * 100%);
    }
  }
}

.c-sns {
  @apply flex items-center;

  @include r.mq(mdless) {
    @apply justify-center;
  }
}

.c-cpoy {
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: 300;

  @include r.mq(mdless) {
    @apply text-center;
    font-size: 2.8vw;
  }
}

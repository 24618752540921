@use "../Components";
@use '../foundation/mixin' as r;
@use '../abstruct/variable' as v;

[data-whatinput='mouse'] *:focus,
[data-whatinput='touch'] *:focus {
  outline: none;
}

.c-pages_head {
  @apply text-center;
  width: calc(383 / 677 * 100%);
  margin: 8% auto 0;
}

.c-pages_bg {
  @include r.mq(mdless) {
    width: 100%;
    margin: 0 auto 0;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding-top: calc(207 / 750 * 100%);
      content: '';
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
    }
  }
}

.c-pages_title {
  width: calc(117 / 383 * 100%);
  margin: 0 auto;

  @include r.mq(mdless) {
    width: calc(483 / 750 * 100%);
    padding-top: 27%;
    margin: 18% auto 0;

    img {
      width: 100%;
    }
  }
}

.c-body {
  width: calc(380 / 677 * 100%);
  margin: 2vw auto 0;

  @include r.mq(mdless) {
    width: calc(577 / 750 * 100%);
    margin: 6vw auto 0;
  }
}

.c-key {
  margin: 4% auto 0;

  @include r.mq(mdless) {
    // width: calc(567 / 750 * 100%);
  }
}

.c-hero {
  margin-top: 10%;

  @include r.mq(mdless) {
    width: calc(567 / 750 * 100%);
  }
}

.c-bg {
  position: relative;
  padding-bottom: 5%;
  // overflow: hidden;

  @include r.mq(mdless) {
    padding-bottom: 12%;
    // margin-top: 22%;
    // overflow: hidden;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 0;
    margin-top: 6%;
    overflow: hidden;
    content: '';
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;

    @include r.mq(mdless) {
      margin-top: 27%;
    }
  }
}

.c-pages_subtitle {
  margin-top: 2vw;
  font-size: 1.6vw;
  @apply font-bold;

  @include r.mq(mdless) {
    margin-top: 4vw;
    font-size: (1vw * 35 / 750 * 100);
  }
}

.c-pages_text {
  margin-top: calc(10 / 383 * 100%);
  font-size: 1.25vw;
  line-height: 3vw;

  @include r.mq(mdless) {
    font-size: (1vw * 30 / 750 * 100);
    line-height: 7vw;
    font-feature-settings: 'palt';
  }
}

.c-pages-top-view {
  position: fixed;
  right: 16vw;
  bottom: 13rem;
  z-index: 19;
  display: block;
  width: 4rem;
  height: 4rem;

  @include r.mq(mdless) {
    right: 0;
    right: 3vw;
    bottom: 0;
    bottom: 3vw;
  }
}

.js-page-top {
  display: block;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    padding-top: 5rem;
    content: '';
    background-image: url(v.$filePath + 'assets/images/common/img_scroll_top.svg');
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

.color01 {
  color: #000;
}

.color02 {
  color: v.$color_vision;
}

.color03 {
  color: v.$color_service;
}

.color04 {
  color: v.$color_works;
}

.color05 {
  color: v.$color_about;
}

.color06 {
  color: v.$color_supporters;
}

.color07 {
  color: v.$color_recruit;
}

.color08 {
  color: v.$color_news;
}

.color09 {
  color: v.$color_contact;
}

[data-observe] {
  opacity: 0;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  transform: translateY(20%);
}
